<template>
  <div class="type-return-coupon">
    <AddOnItem
      :language="language"
      :abt-info="abtInfo"
      :drawer-config="drawerConfig"
      :query-info="{
        ...$attrs.queryInfo,
        ...anotherInfo,
      }"
      :type="$attrs.type"
      :sa-info="$attrs.saInfo"
      :config="config"
    >
      <template slot="header">
        <transition 
          name="header-animate"
        >
          <component
            :is="headerName"
            v-if="!isFullScreen"
            :language="language"
            :promotion="promotion"
          />
          <FullScreenHead
            v-else
            :type=" headerName === 'NewUserHead' ? 'newUserReturnCoupon' : 'returnCoupon'"
            :tips="headerTitle"
          />
        </transition>
      </template>
      <template #footer="{ viewCart }">
        <component 
          :is="footerName"
          :language="language"
          :desc="promotion.tip"
          :progress-rate="promotion.processCount"
          @back="viewCart"
        />
      </template>
    </AddOnItem>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { EventProgressUpdate } from '../../utils/event.js'
import { template } from '@shein/common-function'
import { calcPromotionProcess } from 'public/src/pages/common/addOnItem/utils/index.js'
import { isNewUserReturnCoupon } from '@/public/src/pages/cartNew/utils/index'

export default {
  name: 'TypeReturnCoupon',
  components: {
    AddOnItem: () => import(/* webpackChunkName: "add_on_item" */'../AddOnItem.vue'),
    NormalHead: () => import(/* webpackChunkName: "add_on_item" */'./normal/Head.vue'),
    NormalFoot: () => import(/* webpackChunkName: "add_on_item" */'./normal/Foot.vue'),
    FullScreenHead: () => import(/* webpackChunkName: "add_on_item" */'public/src/pages/common/addOnItem/comps/base/FullScreenHead.vue'),
    NewUserHead: () => import(/* webpackChunkName: "add_on_item" */'./new-user/Head.vue'),
  },
  props: {
    promotion: {
      type: Object,
      default: () => ({}),
    },
    drawerConfig: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isShowAnimation4: false, // 展示过动效 4
      isShowAnimation5: false, // 展示过动效 5
    }
  },
  computed: {
    ...mapState(['abtInfo', 'language', 'progressDataPromise', 'isFullScreen']),
    anotherInfo() {
      const { diffAmount, lable_id } = this.promotion
      const jsonRuleId = this.abtInfo?.addnewhotJson?.param || {}
      return {
        goodsPrice: ((diffAmount?.usdAmount || 0) * 100).toFixed(0), // 凑单差额价格(美分)
        includeTspId: lable_id,
        // excludeTspId

        jsonRuleId,
        location: 'addnewhotJson',
      }
    },
    headerTitle() {
      return this.promotion.couponNum > 1 ? this.language.SHEIN_KEY_PWA_27303 : this.language.SHEIN_KEY_PWA_27302
    },
    headerName() {
      if (this.abtInfo?.orderCouponNewUser?.param?.orderCoupon_newUser === 'new' && isNewUserReturnCoupon(this.promotion)) {
        return 'NewUserHead'
      } 
      return 'NormalHead'
    },
    footerName() {
      return 'NormalFoot'
    }
  },
  watch: {
    'promotion.processCount'(v, o) {
      this.progressDataPromise?.resolve?.({
        progressData: this.getProgressData(),
        bubbleText: this.getBubbleText(o),
        noAnimation: false,
      })
    }
  },
  mounted() {
    EventProgressUpdate.notifySync(this.getProgressData())
  },
  methods: {
    getProgressData() {
      const { promotionRangeInfo, rules } = this.promotion
      const { range, next } = promotionRangeInfo || {}
      if (!Array.isArray(rules)) return []
      return rules.map((rule, index) => {
        return {
          progressRate: calcPromotionProcess(rule, rules[index - 1], index, promotionRangeInfo), // 凑单进度
          ruleIndex: index, // 当前档位
          activeRuleIndex: next == 0 ? -1 : range, // 正在凑单档位
        }
      })
    },
    getBubbleText(oldProcess) {
      // 上一次凑单进度已经到100了, 就不提示了
      if (oldProcess >= 100) {
        return ''
      }
      let text = ''
      // 返券 当前只有 1 档
      if (this.promotion.range === 0) {
        // 动效 4 只展示一次，展示过动效 5 不展示动效 4
        if (this.isShowAnimation4 || this.isShowAnimation5) return text
        this.isShowAnimation4 = true
        text = template(this.promotion?.diffAmount?.amountWithSymbol || 0, this.language.SHEIN_KEY_PWA_27314)
      } else if (this.promotion.range === 1) {
        // 动效 5 只展示一次
        if (this.isShowAnimation5) return text
        this.isShowAnimation5 = true
        text = this.language.SHEIN_KEY_PWA_27315
      }
      return text
    },
    getSaExtraData() {
      const { processCount, promotion_id } = this.promotion
      return {
        promotion_code: promotion_id,
        is_satisfied: processCount >= 100 ? 1 : 0,
        coupon_change: processCount >= 100 ? 1 : 0,
        is_couponbag: this.promotion.couponNum > 1 ? 1 : 0,
        newuser_label: isNewUserReturnCoupon(this.promotion) ? 1 : 0,
      }
    },
  },
}
</script>

<style lang="less" scoped>
.type-return-coupon {
  height: 100%;
  .header-animate-enter-active,.header-animate-leave-active { /* stylelint-disable-line */
    transition: all .2s;
  }
  .header-animate-enter,
  .header-animate-leave-to { /* stylelint-disable-line */
    height: 0;
    opacity: .6;
  }
}
</style>
