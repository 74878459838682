<template>
  <div class="other-promotion-main">
    <AddOnItem
      :language="language"
      :abt-info="abtInfo"
      :drawer-config="drawerConfig"
      :query-info="{
        ...$attrs.queryInfo,
        ...anotherInfo,
      }"
      :type="$attrs.type"
      :sa-info="$attrs.saInfo"
      :config="config"
    >
      <template #header>
        <transition
          name="header-animate"
        >
          <AddOnHeader
            :language="language"
            :promo="promotion"
            :is-full-screen="isFullScreen"
            :type="promotion.addOnType"
          />
        </transition>
      </template>
      <template #footer="{ viewCart }">
        <Foot
          :language="language"
          :promotion="promotion"
          @back="viewCart"
        />
      </template>
    </AddOnItem>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { EventProgressUpdate } from '../../utils/event.js'
import { calcPromotionProcess } from 'public/src/pages/common/addOnItem/utils/index.js'
import _ from 'lodash'
export default {
  name: 'OtherPromotionMain',
  components: {
    AddOnItem: () => import(/* webpackChunkName: "add_on_item" */'../AddOnItem.vue'),
    Foot: () => import(/* webpackChunkName: "add_on_item" */'./Foot.vue'),
    AddOnHeader: () => import(/* webpackChunkName: "add_on_item" */'./Header.vue'),
  },
  props: {
    promotion: {
      type: Object,
      default: () => ({}),
    },
    drawerConfig: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isShowBubble5: false,
      couponChange: 0,
      isShowBubble4: false,
    }
  },
  computed: {
    ...mapState(['abtInfo', 'language', 'isFullScreen', 'progressDataPromise']),
    anotherInfo() {
      const diffAmount = this.promotion?.promotion_range_info?.diff?.value_amount
      let goodsPrice = ''
      if(diffAmount) goodsPrice = ((diffAmount?.usdAmount || 0) * 100).toFixed(0)
      const { lable_id } = this.promotion
      const jsonRuleId = this.abtInfo?.addnewhotJson?.param || {}
      return {
        goodsPrice, // 凑单差额价格(美分)
        includeTspId: lable_id,
        // excludeTspId
        jsonRuleId,
        location: 'addnewhotJson',
      }
    },
    // 是否是每满的活动
    isEveryFull() {
      const { type_id, rules = [] } = this.promotion
      
      return type_id == 1 || (type_id == 15 && rules[0].type == 2) || (type_id == 14 && (rules[0].type == 2 || rules[0].type == 4))
    },
    // 是否已满足最大优惠限制（暂时只有type_id=14才有）
    isFullMaxDiscount() {
      const { type_id, rules = [] } = this.promotion
      const { max_discount } = rules?.[0] || {}
      const { hit_range } = this.promotion?.promotion_range_info || {}
      return type_id == 14 && max_discount > 0 && hit_range?.total_discount_amount?.amount >= max_discount
    },
  },
  watch: {
    'promotion.promotion_range_info': {
      handler(newVal, oldVal) {
        this.reload(newVal, oldVal)
      },
      deep: true,
    },
  },
  mounted() {
    let result = this.getProgressData()
    EventProgressUpdate.notifySync(result)
  },
  methods: {
    /**
     * 获取进度条数据
     * @param {*} isLoop 是否循环
     */
    getProgressData(isLoop) {
      if (this.isEveryFull) {
        const { promotion_range_info, rules } = this.promotion
        const { diff } = promotion_range_info || {}
        const { value } = rules?.[0] || {}
        return [
          {
            progressRate: this.isFullMaxDiscount ? 100 : (value - diff.value) / value * 100, // 凑单进度
            ruleIndex: 0, // 当前档位
            activeRuleIndex: 0, // 正在凑单档位
            isLoop,
            isEveryFull: this.isEveryFull,
            range: promotion_range_info?.range,
            isFullMaxDiscount: this.isFullMaxDiscount, // 是否已满足最大优惠限制
          }
        ]
      }
      const { promotion_range_info, rules } = this.promotion
      const { range, next } = promotion_range_info || {}
      if (!Array.isArray(rules)) return []
      return rules.map((rule, index) => {
        return {
          progressRate: calcPromotionProcess(rule, rules[index - 1], index, promotion_range_info), // 凑单进度
          ruleIndex: index, // 当前档位
          activeRuleIndex: next == 0 ? -1 : range, // 正在凑单档位
        }
      })
    },
    getSaExtraData() {
      const range = this.promotion?.promotion_range_info?.range
      const { rules = [] } = this.promotion
      let is_satisfied = 0
      if(range > 0 && range < rules.length) is_satisfied = 2
      else if(range >= rules.length) is_satisfied = 1
      if(range > 0 && this.isEveryFull) {
        if (this.isFullMaxDiscount) {
          is_satisfied = 1
        } else {
          is_satisfied = 2
        }
      } // loop rules special handle
      // coupon_change
      return {
        coupon_change: this.couponChange,
        promotion_code: this.promotion?.promotion_id,
        is_satisfied
      }
    },
    reload: _.throttle(function reload(newVal, oldVal) {
      console.log('process update')
      let bubbleText = ''
      let isLoop = false
      if(newVal?.range > oldVal?.range) {
        bubbleText = !this.isShowBubble5 ? this.promotion?.addOnTips?.reachBubbleText : ''
        this.isShowBubble5 = true
        this.couponChange = 1
        isLoop = this.isEveryFull
      } else if(!this.isShowBubble4 && !this.isShowBubble5) {
        this.isShowBubble4 = true
        bubbleText = this.promotion?.addOnTips?.closeBubbleText
      }

      this.progressDataPromise?.resolve?.({
        progressData: this.getProgressData(isLoop),
        bubbleText,
        isLoop,
      })
    }, 1000, {
      'leading': true,
      'trailing': false,
    })
  },
}
</script>
<style lang="less" scoped>
.other-promotion-main {
  height: 100%;
}
</style>
