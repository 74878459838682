<template>
  <div class="add-on-container j-add-on-container">
    <div 
      v-if="visible" 
      class="add-on-container__shade"
    >
    </div>
    <template v-if="errorComponent">
      <NoNetworkEmpty
        v-show="errorComponent == 'ErrorNetwork'"
        :is-empty="true"
        :language="language"
        @online-changed="handleOnlineStatus"
      />
      <component
        v-show="isOnline"
        :is="errorComponent"
        v-bind="{
          visible,
          language,
          statusMsg,
        }"
        @close="handleClose"
      />
    </template>
    <s-drawer
      v-else
      ref="add-on-container__drawer"
      class="container-drawer"
      :class="{
        'half-radius': !isFullScreen,
      }"
      :visible="visible"
      v-bind="drawerCurrentConfig"
      @update:visible="handleClose()"
    >
      <component
        :is="componentNameFromType"
        :ref="componentNameFromType"
        :drawer-config="drawerConfig"
        v-bind="bindProps"
        @close="handleClose"
      />
      <NoNetworkEmpty
        :is-empty="!bindProps.type"
        :language="language"
        @online-changed="handleOnlineStatus"
      />
    </s-drawer>
  </div>
</template>

<script>
import TypeCoupon from './layout/typeCoupon/index.vue'
import TypeReturnCoupon from './layout/typeReturnCoupon/index.vue'
import TypeAccessory from './layout/typeAccessory/index.vue'
import TypeFreeShipping from './layout/typeFreeShipping/index.vue'
import OtherPromotionMain from './layout/commonComponents/main.vue'
import ErrorDisable from './layout/ErrorDisable.vue'
import ErrorNetwork from './layout/ErrorNetwork.vue'
import NoNetworkEmpty from 'public/src/pages/cartNew/components/offline/NoNetworkEmpty.vue'
import { getLanguage, getPageAbt, getCardConfig } from './utils/fetcher.js'
import { HOOKS, COUPON_DISABLE_CODES, ADD_ON_COMP_MAP } from './utils/constants.js'
import { 
  EventExposeAddCart, 
  EventUpdateCart, 
  EventViewCart, 
  EventFetchError, 
  EventClose,
  EventPickItem,
  EventUpdatedIncentivePoint,
  EventUpdateBusinessCart,
  removeAllEvents,
} from './utils/event.js'
import { setCouponDataForAddOnItem } from './utils/couponData.js'
import { isFunction } from '@shein/common-function'
import apiReport from 'public/src/pages/common/apiReport/index.js'
import { mapState, mapMutations } from 'vuex'

import { daEventCenter } from 'public/src/services/eventCenter/index'
import { template } from '@shein/common-function'

daEventCenter.addSubscriber({ modulecode: '1-8-4' })
const { langPath } = gbCommonInfo


export default {
  name: 'AddOnContainer',
  components: {
    TypeCoupon,
    TypeReturnCoupon,
    TypeAccessory,
    TypeFreeShipping,
    ErrorDisable,
    ErrorNetwork,
    OtherPromotionMain,
    NoNetworkEmpty,
  },
  data() {
    return {
      bindProps: {},
      hooks: {}, // 钩子函数
      visible: false,
      language: {}, // 多语言

      statusCode: 0, // 默认为0，正常情况
      statusMsg: '',
      addCartNum: 0, // 加车总值
      isOnline: true,
    }
  },
  computed: {
    ...mapState(['isFullScreen', 'addSize']),
    drawerConfig() {
      return {
        size: '90%',
        maxSize: '90%',
        ...this.bindProps?.drawerConfig,
        'no-header': this.isFullScreen,
        modal: false,
      }
    },
    drawerCurrentConfig() {
      const { size, maxSize } = this.drawerConfig
      return {
        ...this.drawerConfig,
        size: `${parseFloat(size) + (this.addSize || 0)}%`,
        maxSize: `${parseFloat(maxSize) + (this.addSize || 0)}%`,
      }
    },
    componentNameFromType() {
      return ADD_ON_COMP_MAP[this.bindProps?.type]
    },
    errorComponent() {
      if (this.statusCode == 0) return ''
      return COUPON_DISABLE_CODES.includes(+this.statusCode) ? 'ErrorDisable' : 'ErrorNetwork'
    }
  },
  methods: {
    ...mapMutations([
      'setAbtInfo',
      'setCardConfig',
      'setLanguage',
      'resetState',
    ]),
    async open(config) {
      this.visible = true
      await this.fetchAbt()
      this.initConfig(config)
      this.initEvent()
      this.fecthLanguage() // 请求多语言
      apiReport.report({
        apiPath: 'add_popup/bi_parameter_empty',
        parameter_detail: this.bindProps,
      })

      window.addEventListener('popstate', this.routeChange)
    },
    resetAndClose() {
      Object.assign(this.$data, this.$options.data()) // 重置组件状态
      this.resetState() // 重置 store
      // 移除所有eventbus订阅
      removeAllEvents()

      window.removeEventListener('popstate', this.routeChange)
    },
    routeChange() {
      this.resetAndClose()
    },
    initConfig(config) {
      const { props, on } = config
      this.bindProps = props || {}
      this.hooks = on || {}

      this.executeHook(HOOKS.beforeOpen)
    },
    async fetchAbt() {
      const [abt, config] = await Promise.all([getPageAbt(), getCardConfig()])
      this.setAbtInfo(abt)
      this.setCardConfig(config?.info)
    },
    async fecthLanguage() {
      const { language } = await getLanguage()
      this.language = language
      this.setLanguage(language)
    },
    handleClose(info) {
      const ref = this.$refs[this.componentNameFromType]
      const saData = ref?.getSaExtraData?.()
      const closeData = ref?.getClosePayload?.()
      daEventCenter.triggerNotice({
        daId: '1-8-4-12',
        extraData: {
          state: this.bindProps?.saInfo?.state,
          add_cart_number: this.calcAddCartNumber(this.addCartNum, saData),
          ...(saData || {}),
        }
      })

      this.executeHook(HOOKS.close, info || closeData)

      this.resetAndClose()
    },

    // 多处使用统一监听注册
    initEvent() {
      // 凑单弹窗曝光事件
      EventExposeAddCart.subscribe({
        callback: ({ service_error = '' } = {}) => {
          const saData = this.$refs[this.componentNameFromType]?.getSaExtraData?.()
          daEventCenter.triggerNotice({
            daId: '1-8-4-13',
            extraData: {
              state: this.bindProps?.saInfo?.state,
              parameter_detail: this.bindProps,
              promotion_code: service_error ? '-' : saData?.promotion_code,
              is_satisfied: saData?.is_satisfied,
              service_error,
              is_multiple_coupons: saData?.is_multiple_coupons || '-',
              newuser_label: saData?.newuser_label || 0,
            }
          })
        }
      })
      // 内部加车事件
      EventUpdateCart.subscribe({
        callback: (cartInfo) => {
          this.addCartNum += 1
          this.executeHook(HOOKS.updateCart, cartInfo)
        }
      })
      // 点击viewCart
      EventViewCart.subscribe({
        callback: (data) => {
          const saData = this.$refs[this.componentNameFromType]?.getSaExtraData?.()
          daEventCenter.triggerNotice({
            daId: '1-8-4-10',
            extraData: {
              state: this.bindProps?.saInfo?.state,
              add_cart_number: this.calcAddCartNumber(this.addCartNum, saData),
              ...(saData || {}),
            }
          })

          this.executeHook(HOOKS.viewCart, data)
          this.resetAndClose()

          // 非购物车页点击底部ViewCart按钮
          if (window?.SaPageInfo?.page_name != 'page_cart') {
            setCouponDataForAddOnItem(data)
            window.location.href = `${langPath}/cart`
          }
        }
      })
      // 组件异常情况
      EventFetchError.subscribe({
        callback: ({ code, msg }) => {
          if (code == 0) return
          this.statusMsg = msg
          this.statusCode = code
        }
      })

      EventClose.subscribe({
        callback: (payload) => {
          this.handleClose(payload)
        }
      })

      EventPickItem.subscribe({
        callback: (payload) => {
          const saData = this.$refs[this.componentNameFromType]?.getSaExtraData?.()
          daEventCenter.triggerNotice({
            daId: '1-8-4-16',
            extraData: {
              add_cart_number: this.calcAddCartNumber(this.addCartNum, saData),
              coupon_change: saData?.coupon_change,
              action: saData?.action,
            }
          })

          this.executeHook(HOOKS.pickItem, payload)
        }
      })
      EventUpdatedIncentivePoint.subscribe({
        callback: (payload) => {
          daEventCenter.triggerNotice({
            daId: '1-8-4-17',
            extraData: {
              scenes: 'add_gotocheckout_info',
              type: payload?.type,
              state: this.bindProps?.saInfo?.state,
            }
          })
        }
      })
      EventUpdateBusinessCart.subscribe({
        callback: (payload) => {
          this.executeHook(HOOKS.updateBusinessCart, payload)
        }
      })
    },

    // 外部钩子
    executeHook(name, info) {
      // 校验接入方入参
      if (!isFunction(this.hooks?.[name])) return
      this.hooks[name](info)
    },

    calcAddCartNumber(addCartNum, saData) {
      const other_add_cart_number = saData?.other_add_cart_number || 0
      let add_cart_number = addCartNum
      // 如果存在 other_add_cart_number, 则内部组件统计了其他加车数量, add_cart_number 需要减去 other_add_cart_number
      if (other_add_cart_number) {
        add_cart_number -= other_add_cart_number
      }
      return add_cart_number
    },
    handleOnlineStatus(val) {
      this.isOnline = val
    },
    updateOpts({ props } = {}) {
      // 特殊场景：免邮凑单弹窗，在 checkout_shipping_add 场景下，切换活动，只更新进度条和提示语
      if (this.bindProps.saInfo.activity_from === 'checkout_shipping_add' && this.bindProps.type === 'freeShipping') {
        if (props.promotion.isActivityEnd) {
          this.handleClose()
          return
        }
        if (props.promotion.isActivityStop) {
          this.bindProps.promotion = Object.assign({}, this.bindProps.promotion, {
            ...props.promotion,
            tip: template(props.promotion.shippingMethodName, this.language['SHEIN_KEY_PWA_30443']),
            progressRate: 100,
          })
          return
        }
      }
      this.bindProps = Object.assign({}, this.bindProps, props)
    }
  },
}

</script>

<style lang="less" scoped>
.add-on-container {
  &__shade {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.4);
    z-index: 1000; /* stylelint-disable-line declaration-property-value-blacklist */
    transform: translate3d(0px, 0px, 1000px);
  }
  /deep/.S-drawer__container_btt {
    border-radius: unset;
  }
}
.container-drawer {
  &.half-radius /deep/ .S-drawer__container {
    border-radius: 14px 14px 0 0;
    overflow: visible;
  }
  
  /deep/ .S-drawer__header {
    height: 0;
    min-height: 0;
    border-bottom: none;
    z-index: 9;
  }
  /deep/ .S-drawer__body {
    height: 100%;
    background-color: transparent;
    overflow: visible;
  }
}
</style>
